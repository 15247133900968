export const applicationTableMockData = [
  {
    id: 1,
    name: "google.com",
    atRiskPasswords: 4,
    totalPasswords: 10,
    atRiskMembers: 2,
    totalMembers: 5,
    isMarkedAsCritical: false,
  },
  {
    id: 2,
    name: "facebook.com",
    atRiskPasswords: 3,
    totalPasswords: 8,
    atRiskMembers: 1,
    totalMembers: 3,
    isMarkedAsCritical: false,
  },
  {
    id: 3,
    name: "twitter.com",
    atRiskPasswords: 2,
    totalPasswords: 6,
    atRiskMembers: 0,
    totalMembers: 2,
    isMarkedAsCritical: false,
  },
  {
    id: 4,
    name: "linkedin.com",
    atRiskPasswords: 1,
    totalPasswords: 4,
    atRiskMembers: 0,
    totalMembers: 1,
    isMarkedAsCritical: false,
  },
  {
    id: 5,
    name: "instagram.com",
    atRiskPasswords: 0,
    totalPasswords: 2,
    atRiskMembers: 0,
    totalMembers: 0,
    isMarkedAsCritical: false,
  },
  {
    id: 6,
    name: "tiktok.com",
    atRiskPasswords: 0,
    totalPasswords: 1,
    atRiskMembers: 0,
    totalMembers: 0,
    isMarkedAsCritical: false,
  },
];
